<template>
    <div>
        <h3>Book your Teaching Sessions</h3>
        <h5>First month from {{ interval1.start.setLocale('en-gb').toLocaleString() }} to {{ interval1.end.setLocale('en-gb').toLocaleString() }}</h5>
        <p class="text-muted">Please fully book if possible</p>
        <iris-appointment-block v-bind="appointmentBlockPropsBase" :interval="interval1" @slotsUnbooked="updateSlots" @customCalendersSet="updateCalender" />
        <div class="mt-2">
            <b-button variant="primary" @click="continueBooking">Continue</b-button>
        </div>
        <hr />
        <h5>Month 2 from {{ interval2.start.setLocale('en-gb').toLocaleString() }} to {{ interval2.end.setLocale('en-gb').toLocaleString() }}</h5>
        <p class="text-muted">Optional</p>
        <iris-appointment-block v-bind="appointmentBlockPropsBase" :interval="interval2" @customCalendersSet="updateCalender" />
        <hr />
        <h5>Month 3 from {{ interval3.start.setLocale('en-gb').toLocaleString() }} to {{ interval3.end.setLocale('en-gb').toLocaleString() }}</h5>
        <p class="text-muted">Optional</p>
        <iris-appointment-block v-bind="appointmentBlockPropsBase" :interval="interval3" @customCalendersSet="updateCalender" />
        <hr />
        <div class="mt-2">
            <b-button variant="primary" @click="continueBooking">Continue</b-button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { DateTime, Duration, Interval } from 'luxon'
import { TIMEZONE } from '~~/iris/src/constants'
import { TutorCalendarsFragment } from '~~/iris/src/queries'
import type { IrisStore } from '~~/iris/src/store/types'

const store: IrisStore = getCurrentInstance().proxy.$store

const slots = ref<number>(-1)

export type AppointmentBlockProps = {
        /** subscriber id */
        subscriberId: number
        /** subscribers email */
        email: string
        /** first name */
        firstName: string
        /** last name  */
        lastName: string
        /** number of slots available */
        slotsAvailable: number
        /** time period allowed */
        interval?: Interval
        /** optional invoice id */
        invoiceId?: string
        /** plan string */
        planCode: string
        /** fetchCalendarsPromise */
        customCalendersPromise: Promise<TutorCalendarsFragment> | undefined
}

const billingCycleLength = computed(() => Duration.fromISO(store.getters.currentNewPricingPlan.billingCycleLength.toISOString()).minus({ millisecond: 1}))

const interval1 = computed<Interval>(() => {
    return Interval.fromDateTimes(DateTime.now().setZone(TIMEZONE).startOf('day'), DateTime.fromJSDate(store.getters['instalments/firstPaymentDue'].toDate(), {zone: TIMEZONE}).endOf('day'))
})
const interval2 = computed(() => Interval.after(interval1.value.end.plus({ millisecond: 1}), billingCycleLength.value))
const interval3 = computed(() => Interval.after(interval2.value.end.plus({ millisecond: 1}), billingCycleLength.value))
const customCalendersPromise = ref<Promise<TutorCalendarsFragment> | undefined>()

const updateCalender = (value: Promise<TutorCalendarsFragment> | undefined) => {
    customCalendersPromise.value = value
}

const instance = getCurrentInstance().proxy

const continueBooking = () => {
    if (slots.value === 0) {
        return instance.$emit('ok')
    }
    return instance.$bvModal.msgBoxConfirm('Not all sessions for the first month have been booked yet', {
        title: 'Confirm skipping sessions',
        okTitle: 'Continue without booking sessions'
    }).then(result => {
        if (result) {
            return instance.$emit('skip')
        }
    })
}

const appointmentBlockPropsBase = computed<Omit<AppointmentBlockProps, 'interval'>>(() => {
    return {
        subscriberId: store.state.createAccountResult.subscriberId,
        email: store.state.familyInformation.email,
        firstName: store.state.familyInformation.firstName,
        lastName: store.state.familyInformation.lastName,
        slotsAvailable: store.getters.currentNewPricingPlan?.includedSessions ?? 0,
        planCode: store.getters.paymentMethodForCss,
        customCalendersPromise: customCalendersPromise.value
    }
})

const updateSlots = (evt: number) => {
    slots.value = evt
}

    // const props = defineProps<{
    //     /** subscriber id */
    //     subscriberId: number
    //     /** subscribers email */
    //     email: string
    //     /** first name */
    //     firstName: string
    //     /** last name  */
    //     lastName: string
    //     /** number of slots available */
    //     slotsAvailable: number
    //     /** time period allowed */
    //     interval: Interval
    // }>()


</script>